<template>
  <el-dialog
    top="10vh"
    :close-on-click-modal="false"
    class="import-add"
    :visible.sync="dialogVisible"
  >
   <div class="title">查看详情</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
	
	<div class="body">
		 <div style="margin-bottom: 20px;margin-top: -30px;">{{name}}参与的培训班</div>
		<table-list
		  class="tableList"
		  :data="list"
		  :height="250"
		  :options="{
			singleCurrent: true,
			height: 600,
		  }"
		  :columns="columns(this)"
		></table-list>
	</div>
    <!-- <div class="body">
		<div style="margin-top: -30px;">查看详情</div>
      <div
        v-for="(item, index) in list"
        :key="index"
        class="viewContent"
        :style="index % 2 === 1 ? ' height: 50px;' : ''"
      >
        <div class="nameText" :style="index > 0 ? 'border-top: 0px;' : ''">{{ item.name }}</div>
        <div class="nameText" :style="index > 0 ? 'border-top: 0px;' : ''">{{ item.name }}</div>
        <div class="contentText" :style="index > 0 ? 'border-top: 0px;' : ''">{{ item.title }}</div>
      </div>
    </div> -->
 <!--   <div class="footer">
      <el-button type="primary" @click="dialogVisible = false">确定</el-button>
    </div> -->
  </el-dialog>
</template>
<script>
	
import { getClassInfoList } from '@/api/student'
import to from 'await-to'
import TableList from '@/components/TableList'
// 表格列配置
const columns = () => [
  {
    prop: 'className',
    label: '培训班名称',
  },
  {
    prop: 'classBeginTime',
    label: '培训开始时间',
  },
  {
    prop: 'classEndTime',
    label: '培训结束时间',
  },
]
export default {
  name: 'LookClassView',
  components: {
    TableList,
  },
  data() {
    return {
      dialogVisible: false,
      columns,
      list: [],
	  name:''
    }
  },
  methods: {
    show(item) {
      this.dialogVisible = true
      console.log(item)
	  this.name = item.studentName
	  this.getClassList(item.studentId)
    },
	async getClassList(studentId) {
	  const [res, err] = await to(getClassInfoList({ studentId : studentId }))
	  if (err) return this.$message.warning(err.msg)
	  console.log(res);
	  this.list = res.data
	},
  },
}
</script>
<style lang="scss" scoped>
.import-add {
  ::v-deep.el-dialog {
    position: relative;
    width: 720px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 14px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .body {
      box-sizing: border-box;
      padding: 50px 20px 10px 20px;
      .viewContent {
        display: flex;
        font-size: 16px;
        height: 70px;
        .nameText {
          border-bottom: 1px solid #c9c9c9;
          border-top: 1px solid #c9c9c9;
          border-left: 1px solid #c9c9c9;
          width: 160px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .contentText {
          border: 1px solid #c9c9c9;
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: left;
          padding: 20px;
        }
      }
    }
    .title {
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .footer {
      padding: 20px 52px 20px;
      text-align: center;
      // border-top: 1px solid #c7c7c7;
    }
  }
}
</style>
