<template>
  <div class="student-list">
    <table-list
      title="员工列表"
      :loading="loading"
      :data="studentList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true, fixScrollTable: true }"
      class="tableList"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <StudentInvite ref="StudentInvite" />
    <look-class-view ref="lookClassView"></look-class-view>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '添加员工',
    method: _this.handleAdd,
  },
  {
    label: '批量添加员工',
    method: _this.handleBatchAdd,
  },
  {
    label: '邀请员工',
    method: _this.handleInvite,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '员工姓名/手机号',
    prop: 'keyword',
  },
  {
    label: '身份证/护照',
    prop: 'cardNo',
  },
  {
    label: '筛选部门',
    type: 'select',
    prop: 'deptId',
    children: _this.deptList.map(item => ({ value: item.deptId, label: item.deptName })),
  },
  {
    label: '班级',
    type: 'select',
    prop: 'classId',
    children: _this.classList.map(item => ({ value: item.classId, label: item.className })),
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'studentName',
    label: '员工姓名',
    minWidth: 120,
  },
  {
    prop: 'mobile',
    label: '手机号',
    minWidth: 150,
  },
  {
    prop: 'cardNo',
    label: '身份证/护照',
    minWidth: 200,
  },

  {
    prop: 'deptInfo',
    label: '所属部门',
    formatter: row => {
      let nameArray = row.deptInfo.map(element => element.deptName)
      return nameArray.join('/')
    },
    minWidth: 150,
  },
  {
    prop: 'classInfo',
    label: '参与培训的班级',
    minWidth: 230,
    render: (h, { row }) => {
      return [
        h(
          'el-button',
          {
            props: { type: 'text' },
            on: {
              click: () => {
                _this.onLookClassView(row)
              },
            },
          },
          '查看',
        ),
      ]
    },
    // render: (h, { row }) => {
    //   return h(
    //     'div',
    //     {
    //       class: 'tableContent',
    //       domProps: {
    //         title: row.classInfo.map(v => v.className),
    //       },
    //     },
    //     [
    //       row.classInfo.map(v => {
    //         return row.classInfo.length
    //           ? h(
    //               'span',
    //               v !== row.classInfo[row.classInfo.length - 1] ? v.className + '、' : v.className,
    //             )
    //           : ''
    //       }),
    //     ],
    //   )
    // },
  },
  {
    prop: 'registerTime',
    label: '注册时间',
    sortable: 'sortable',
    minWidth: 150,
  },
  {
    prop: 'lastLoginTime',
    label: '最后一次登录时间',
    sortable: 'sortable',
    minWidth: 200,
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
]
import TableList from '@/components/TableList'
import StudentInvite from './components/StudentInvite'
import { students, studentRemove } from '@/api/student'
import { deptSelect } from '@/api/dept'
import { classSelect } from '@/api/class'

import LookClassView from './components/LookClassView.vue'
import to from 'await-to'
export default {
  name: 'StudentList',
  components: {
    TableList,
    StudentInvite,
    LookClassView,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      loading: false,
      studentList: [],
      selectStudents: [],
      deptList: [],
      classList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getStudents()
    this.deptSelect()
    this.classSelect()
  },
  methods: {
    sortable() {
      console.log(555)
    },
    async getStudents() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(students({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.studentList = res.data.list
      this.pager.total = res.data.total
    },
    // 部門下拉
    async deptSelect() {
      const [res, err] = await to(deptSelect({}))
      console.log(res)
      if (err) return this.$message.warning(err.msg)
      this.deptList = res.data
    },
    // 班級下拉
    async classSelect() {
      const [res, err] = await to(classSelect({}))
      if (err) return this.$message.warning(err.msg)
      this.classList = res.data
    },
    onLookClassView(row) {
      console.log(row)
      this.$refs.lookClassView.show(row)
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getStudents()
    },
    handleAdd() {
      this.$router.push('/student/add')
    },
    handleBatchAdd() {
      this.$router.push('/student/batchAdd')
    },
    // 编辑
    handleEdit({ studentId }) {
      this.$router.push(`/student/edit/${studentId}`)
    },
    // 删除
    async handleDelete() {
      if (!this.selectStudents.length) return this.$message.warning('请先选择要删除的员工')
      let studentIds = []
      this.selectStudents.map(item => studentIds.push(item.studentId))
      this.$confirm('确认删除员工吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(studentRemove({ studentIds }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getStudents()
      })
    },
    // 邀请
    handleInvite() {
      this.$refs['StudentInvite'].show()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectStudents = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getStudents()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getStudents()
    },
  },
}
</script>
<style scoped lang="scss">
.tableList ::v-deep .el-table tr .tableContent {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  cursor: pointer;
}
</style>
